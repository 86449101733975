import Shepherd from 'shepherd.js'
export const assignmentTour = {
    methods: {
        startAssignmentTour() {
            const tour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true
                    },
                    scrollTo: { behavior: 'smooth' }
                }
            })
            tour.addStep({
                title: 'Creating assignments',
                text: `To create an assignment, just start a distribution and select the option to create an assignment. Learn more about how assignments work: <a href="https://docs.nuvolos.cloud/user-guides/education-guides/setting-assignments" target="_blank">See documentation</a>`,
                attachTo: {
                    element: '.shepherd-assignment-step-1',
                    on: 'right'
                },
                id: 'assignment-step-1'
            })
            tour.start()
        }
    }
}
