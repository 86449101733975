<template>
    <v-card flat>
        <v-card-text>
            <v-card-title>
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span v-if="bundleType === distributionReasons.ASSIGNMENT" class="secondary--text font-weight-bold">Assignments</span>
                        <span v-else class="secondary--text font-weight-bold">Distributions</span>
                        <v-btn :loading="fetchingSpaceBundles" @click="$store.dispatch('spaceStore/fetchSpaceBundles', $route.params.sid)" icon>
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                    <span v-if="fetchingSpaceBundles" class="caption text-uppercase">Fetching data...</span>
                    <span v-else class="caption text-uppercase">
                        {{ filteredBundlesByType.length }}
                        <span v-if="bundleType === distributionReasons.ASSIGNMENT">Assignments</span>
                        <span v-else>Distributions</span>
                        found
                    </span>
                </div>
                <v-spacer></v-spacer>
                <v-btn v-if="!assignmentsExist" @click="startAssignmentTour" text color="secondary">
                    <v-icon small>add</v-icon>
                    <span class="font-weight-bold">Create a new assignment</span>
                </v-btn>
            </v-card-title>
            <div v-if="filteredBundlesByType.length && !fetchingSpaceBundles">
                <v-data-iterator :items="filteredBundlesByType">
                    <template v-slot:default="props">
                        <div v-for="(item, index) in props.items" :key="index">
                            <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                            <v-list-item two-line>
                                <v-row align="center">
                                    <v-col cols="1">
                                        <v-list-item-avatar>
                                            <v-icon v-if="item.bundle_type_name === distributionReasons.ASSIGNMENT" x-large>assignment</v-icon>
                                            <v-icon v-else x-large>inventory</v-icon>
                                        </v-list-item-avatar>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="d-flex align-center">
                                                    <v-hover v-slot:default="{ hover }">
                                                        <span
                                                            :style="hover ? 'cursor: pointer;' : ''"
                                                            :class="[
                                                                hover ? 'secondary--text' : 'secondary--text',
                                                                'subtitle-1',
                                                                'font-weight-bold',
                                                                'text-decoration-none'
                                                            ]"
                                                            @click="gotoAssignment(item.bid)">
                                                            {{ item.long_id }}
                                                        </span>
                                                    </v-hover>
                                                    <div v-if="!isMasterInstance && item.handins && bundleType === distributionReasons.ASSIGNMENT" class="ml-1">
                                                        <v-chip small v-if="item.handins.length" color="success">Submitted</v-chip>
                                                        <v-chip small v-else-if="isAssignmentOverdue(item.allow_handins_until)" color="warning">Overdue</v-chip>
                                                        <v-chip v-else small color="orange">Submission due</v-chip>
                                                    </div>
                                                </div>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div class="d-flex flex-column subtitle-2">
                                                    <span class="subtitle-2" v-if="item.description">{{ item.description }}</span>
                                                    <div class="mt-2 caption" v-if="item.creation_timestamp">
                                                        <span>Created on</span>
                                                        : {{ item.creation_timestamp | dateTimeToHuman }}
                                                    </div>
                                                    <div class="caption" v-if="item.allow_handins_until">
                                                        <span>Hand-in deadline</span>
                                                        : {{ item.allow_handins_until | dateTimeToHuman }}
                                                    </div>
                                                    <div class="caption" v-if="item.handins && item.handins.length && !isMasterInstance">
                                                        <span>Last submission on {{ item.handins[0].handin_timestamp | dateTimeToHuman }}</span>
                                                    </div>
                                                </div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                                <v-list-item-action>
                                    <div class="d-flex align-center">
                                        <TheSnapshotAssignmentSubmitDialog
                                            v-if="
                                                bundleType === distributionReasons.ASSIGNMENT &&
                                                !isAssignmentOverdue(item.allow_handins_until) &&
                                                !isMasterInstance
                                            "
                                            :bundleData="item" />
                                        <BundleDeleteDialog v-if="isSpaceAdmin" :bundleData="item" />
                                        <BundleEditDialog v-if="isSpaceAdmin && bundleType === distributionReasons.ASSIGNMENT" :bundleData="item" />
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </div>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                    </template>
                </v-data-iterator>
            </div>
            <div v-else-if="!filteredBundlesByType.length && !fetchingSpaceBundles">
                <v-alert text prominent type="info">
                    <div class="d-flex align-center justify-space-between w-100">
                        <div v-if="bundleType === distributionReasons.ASSIGNMENT" class="d-flex flex-column">
                            <span class="font-weight-bold">No assignments found</span>
                            <span>
                                So far no assignments have been created.
                                <span v-if="isSpaceAdmin">
                                    You can create new assignments by staging and sharing objects (files, tables, and applications) as an assignment.
                                </span>
                            </span>
                        </div>
                        <div v-else class="d-flex flex-column">
                            <span class="font-weight-bold">No saved distributions</span>
                            <span>
                                So far no object distributions have been saved. You can create new bundles by staging and sharing objects (files, tables, and
                                applications) and saving them as public / private bundles.
                            </span>
                        </div>
                        <v-btn
                            v-if="isSpaceAdmin && bundleType === distributionReasons.ASSIGNMENT"
                            href="https://docs.nuvolos.cloud/user-guides/education-guides/setting-assignments"
                            target="_blank"
                            outlined
                            small
                            color="info">
                            Learn more
                        </v-btn>
                        <v-btn
                            v-else-if="bundleType === distributionReasons.MATERIAL_SHARING"
                            href="https://docs.nuvolos.cloud/getting-started/nuvolos-basic-concepts/distribution"
                            target="_blank"
                            outlined
                            small
                            color="info">
                            Learn more
                        </v-btn>
                    </div>
                </v-alert>
            </div>
            <div v-else-if="fetchingSpaceBundles">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id"
                    class="my-6">
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { assignmentMethods } from '@/mixins/assignments'
import { assignmentTour } from '@/mixins/assignmentTour'
import { sortDateArray } from '@/utils'

const BundleDeleteDialog = () => import('./TheSnapshotBundleDeleteDialog')
const BundleEditDialog = () => import('./TheSnapshotBundleEditDialog')
const TheSnapshotAssignmentSubmitDialog = () => import('../components/TheSnapshotAssignmentSubmitDialog')

export default {
    components: {
        BundleDeleteDialog,
        TheSnapshotAssignmentSubmitDialog,
        BundleEditDialog
    },
    mixins: [assignmentMethods, assignmentTour],
    props: {
        bundleType: String
    },
    data() {
        return {
            distributionReasons: {
                MATERIAL_SHARING: 'DISTRIBUTION',
                ASSIGNMENT: 'ASSIGNMENT'
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceBundles', 'fetchingSpaceBundles']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'assignmentsExist']),
        ...mapGetters('instanceStore', ['isMasterInstance']),
        filteredBundlesByType() {
            const filteredBundles = this.spaceBundles.filter(bundle => bundle.bundle_type_name === this.bundleType)
            if (this.bundleType === this.distributionReasons.ASSIGNMENT) {
                const sortedBundlesByDeadline = sortDateArray(filteredBundles, 'allow_handins_until', 'descending')
                return sortedBundlesByDeadline
            } else {
                const sortedBundlesByCreationTime = sortDateArray(filteredBundles, 'creation_timestamp', 'descending')
                return sortedBundlesByCreationTime
            }
        }
    }
}
</script>
